import React from 'react';

const CreateSporeView = () => {
    return (
        <div className='flex items-center justify-center'>
            Coming soon...
        </div>
    );
}

export default CreateSporeView;
